import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  ProductService,
  Tariff,
  TariffParams,
  TariffService,
  Category,
  ProductType,
  UserDetails,
  UserService,
  ComparisonMail,
} from '@smart-check/feature-comparison';
import { NotificationService } from '@smart-check/shared/ui';

class Print {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public street?: string,
    public zip?: string,
    public city?: string,
    public emailConsultant?: string,
    public emailCustomer?: string,
    public phone?: string,
    public companyName?: string,
    public gender?: string,
    public name?: string,
    public note?: string,
    public currentTariffPrice?: string,
    public currentTariffPayment?: string,
    public offerTariffPrice?: string,
    public offerTariffPayment?: string,
    public enableForeword?: boolean,
    public enableDonut?: boolean,
    public enableCriteria?: boolean,
    public enableExtraCriteria?: boolean,
    public sendToConsultant?: boolean,
    public enableCriteriaAbout?: boolean,
  ) {}
}

type Image = string | ArrayBuffer | null;

@Component({
  selector: 'print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
  @ViewChild('pdf') pdf: ElementRef;
  model: Print;

  public currentProductType: ProductType = {
    id: 0,
    name: '',
    slug: '',
    alternative_slug: '',
    import_key: '',
    product_type_sparte_id: 0,
    position: 0,
    icon: '',
    reference_tariff_id: 0,
    criterionCount: 0,
    criterions: [],
  };
  public categories: Array<Category>;
  public product: string;
  public currentTariff: Tariff;
  public offerTariff: Tariff;
  public currentCompanyLogoToShow: Image;
  public offerCompanyLogoToShow: string | ArrayBuffer | null;
  public isCurrentLogoLoaded: boolean;
  public isOfferLogoLoaded: boolean;
  public pdfBase64: File;
  public isLogoLoaded: boolean;
  public logo: string | ArrayBuffer | null;
  public userDetails: UserDetails;
  public comparisonMail: ComparisonMail;
  public loadingSend: number = 0;
  public loadingPrint: number = 0;

  constructor(
    private productService: ProductService,
    private tariffService: TariffService,
    private userService: UserService,
    private location: Location,
    private route: ActivatedRoute,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.model = new Print();
    this.model = {
      firstName: '',
      lastName: '',
      emailConsultant: '',
      phone: '',
      companyName: '',
      zip: '',
      city: '',
      emailCustomer: '',
      enableForeword: true,
      enableDonut: true,
      enableCriteria: true,
      enableExtraCriteria: true,
      enableCriteriaAbout: true,
      sendToConsultant: false,
    };
    this.userService.getUserLogo().subscribe({
      next: (response: Blob) => {
        this.createImageFromBlobPrint(response);
        this.isLogoLoaded = true;
      },
      error: () => {
        this.isLogoLoaded = false;
      },
    });

    this.userService.getUserDetails().subscribe({
      next: (response: UserDetails) => {
        this.userDetails = response;
      },
      complete: () => {
        if (!this.userDetails.anonymous) {
          this.model.firstName = this.userDetails.firstName;
          this.model.lastName = this.userDetails.lastName;
          this.model.emailConsultant = this.userDetails.email;
          this.model.companyName = this.userDetails.companyName;
          this.model.phone = this.userDetails.phone;
          this.model.zip = this.userDetails.zip;
          this.model.city = this.userDetails.city;

          if (this.userDetails.street && this.userDetails.streetNumber) {
            this.model.street =
              this.userDetails.street + ' ' + this.userDetails.streetNumber;
          }
        }
      },
    });

    this.currentProductType.id = parseInt(
      this.route.snapshot.paramMap.get('product')!
    );

    this.tariffService
    .getCriterionsSorted(this.currentProductType.id)
    .subscribe({
      next: (response: Array<Category>) => {
        this.categories = response;
      },
    });

    this.productService
      .getCurrentProductType(this.currentProductType.id)
      .subscribe({
        next: (response: ProductType) => {
          this.currentProductType = response;
        },
      });

    this.route.params.subscribe((paramMap) => {
      if (paramMap['currentTariff'] !== 'null') {
        const currentTariffParams: TariffParams = {
          idOrSlug: paramMap['currentTariff'],
          withCrit: true,
          onlyActive: false,
        };
        this.showCurrentTariff(currentTariffParams);
      }

      if (paramMap['offerTariff']) {
        const offerTariffParams: TariffParams = {
          idOrSlug: paramMap['offerTariff'],
          withCrit: true,
          onlyActive: true,
        };
        this.showOfferTariff(offerTariffParams);
      }
    });
  }

  getProductFullName(id: number): string {
    return this.productService.getProductName(id);
  }

  createImageFromBlobPrint(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logo = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  showOfferTariff(offerTariffParams: TariffParams) {
    this.tariffService.getSingleTariff(offerTariffParams).subscribe({
      next: (response: Tariff) => {
        this.offerTariff = response;
        this.tariffService
          .getCompanyLogo(this.offerTariff.company?.id)
          .subscribe({
            next: (response: Blob) => {
              this.createImageFromBlobOfferCompanyPrint(response);
              this.isOfferLogoLoaded = true;
            },
            error: (error) => {
              this.isOfferLogoLoaded = false;
            },
          });
      },
    });
  }

  showCurrentTariff(currentTariffParams: TariffParams) {
    this.tariffService.getSingleTariff(currentTariffParams).subscribe({
      next: (response: Tariff) => {
        this.currentTariff = response;
        this.tariffService
          .getCompanyLogo(this.currentTariff.company?.id)
          .subscribe({
            next: (response: Blob) => {
              this.createImageFromBlobCurrentCompanyPrint(response);
              this.isCurrentLogoLoaded = true;
            },
            error: (error) => {
              this.isCurrentLogoLoaded = false;
            },
          });
      },
    });
  }

  createImageFromBlobCurrentCompanyPrint(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.currentCompanyLogoToShow = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  createImageFromBlobOfferCompanyPrint(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.offerCompanyLogoToShow = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  isCriterionSatisfied(tariff: Tariff, name: any, index: number): boolean {
    return this.tariffService.isCriterionSatisfied(tariff, name, index);
  }

  getCriterionValue(tariff: Tariff, name: any, index: number): string {
    return this.tariffService.getCriterionValue(tariff, name, index);
  }

  getCategoryScore(tariff: Tariff, name: any): number {
    return this.tariffService.getCategoryScore(tariff, name);
  }

  back(): void {
    this.location.back();
  }

  savePdf(send: boolean): void {
    if (send) {
      this.loadingSend = 1;
    } else {
      this.loadingPrint = 1;
    }
    this.tariffService
      .generatePdf(
        '<html lang="de"><head><meta http-equiv="Content-Type" content="text/html;charset=UTF-8"/></head><body>' +
          this.pdf.nativeElement.innerHTML +
          '</body></html>',
        this.currentProductType.name!
      )
      .subscribe({
        next: (response: File) => {
          const file = new Blob([response], { type: 'application/pdf' });
          if (!send) {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            const notifier = this.injector.get(NotificationService);
            notifier.showSuccess(
              'Das PDF wird in einem neuen Tab Ihres Browsers geöffnet'
            );
          } else {
            this.pdfBase64 = new File(
              [file],
              this.currentProductType.name! + '.pdf',
              {
                type: 'application/pdf',
              }
            );
            this.sendPdf();
          }
        },
        complete: () => {
          if (!send) {
            this.loadingPrint = 2;
          }
        },
      });
  }

  sendPdf() {
    this.comparisonMail = {
      gender: this.model?.gender!,
      name: this.model?.name!,
      sendToConsultant: this.model.sendToConsultant!.toString(),
      emailConsultant: this.model.emailConsultant!,
      emailCustomer: this.model.emailCustomer!,
      productType: this.currentProductType.name!,
      currentTariff: this.currentTariff?.title!,
      offerTariff: this.offerTariff?.title!,
      file: this.pdfBase64,
    };
    this.tariffService.emailComparison(this.comparisonMail).subscribe({
      next: (response) => {
        if (response === 0) {
          this.loadingSend = 2;
          const notifier = this.injector.get(NotificationService);
          notifier.showSuccess(
            'Das PDF wurde erfolgreich per E-Mail versendet'
          );
        }
      },
    });
  }
}
