import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-criterion',
  templateUrl: './dialog-criterion.component.html',
})
export class DialogCriterionComponent {
  public name: string;
  public aboutLong: string;
  constructor(
    public dialogRef: MatDialogRef<DialogCriterionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.name = data.name;
    this.aboutLong = data.aboutLong;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
