<div mat-dialog-title data-cy="title"><b>Beihilfesachverhalt</b></div>
<mat-dialog-content>
  <mat-grid-list cols="7" rowHeight="50px">
    <mat-grid-tile class="header-top" colspan="2" rowspan="2"
    ><b>Träger</b></mat-grid-tile
    >
    <mat-grid-tile class="header-top" colspan="2" rowspan="1"
    ><b>Beihilfeempfänger</b></mat-grid-tile
    >
    <mat-grid-tile class="header-top" colspan="1" rowspan="1"
    ><b>Ambulant</b></mat-grid-tile
    >
    <mat-grid-tile class="header-top" colspan="2" rowspan="1"
    ><b>Stationär</b></mat-grid-tile
    >
    <mat-grid-tile class="header-top" colspan="3" rowspan="1"></mat-grid-tile>
    <mat-grid-tile class="header-top" colspan="1" rowspan="1"
    ><small>Regelversorgung</small></mat-grid-tile
    >
    <mat-grid-tile class="header-top" colspan="1" rowspan="1"
    ><small>Wahlleistungen</small>
    </mat-grid-tile>
    <mat-grid-tile class="header-left" colspan="2" rowspan="4"
    ><div class="content">
      Bund, Bayern, Baden-Württemberg,Nordrhein-Westfahlen. Rheinland-Pfalz,
      Sachsen, Sachsen-Anhalt, Thüringen
    </div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">Beihilfeberechtigter</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">
      Beihilfeberechtigter mit zwei oder mehr Kinder/Pension
    </div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">mittelbar Beihilfeberechtigt</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">Kind</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">80%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">80%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">80%</mat-grid-tile>
    <mat-grid-tile class="header-left" colspan="2" rowspan="4"
    ><div class="content">
      Berlin, Brandenburg, Hamburg, Mecklenburg-Vorpommern, Niedersachsen,
      Saarland, Schleswig-Holstein
    </div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">Beihilfeberechtigter</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">0%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">
      Beihilfeberechtigter mit zwei oder mehr Kinder/Pension
    </div>
    </mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">0%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">mittelbar Beihilfeberechtigt</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">0%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">Kind</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">80%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">80%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">0%</mat-grid-tile>
    <mat-grid-tile class="header header-left" colspan="2" rowspan="2"
    ><div class="content">
      Hessen, Bremen
      <small
      >(Je berücksichtigungsfähiges Kind/Ehepartner erhöht sich der Satz um
        5%, bis max. 70%; stationärer Bereich Hessen max. 85%)</small
      >
    </div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">Beihilfeberechtigter ledig</div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">50%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">65%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">65%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="2" rowspan="1"
    ><div class="content">
      Beihilfebrechtigter verheiratet/verwitwet
    </div></mat-grid-tile
    >
    <mat-grid-tile class="tile" colspan="1" rowspan="1">55%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
    <mat-grid-tile class="tile" colspan="1" rowspan="1">70%</mat-grid-tile>
  </mat-grid-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()" data-cy="close">
    Schließen
  </button>
</mat-dialog-actions>
