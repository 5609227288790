import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ProductType,
  ProductGroups,
  ProductTypeParams,
} from '@smart-check/feature-comparison';
import { CurrentUser, UtilsService, APP_CONFIG } from '@smart-check/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public user: CurrentUser = JSON.parse(localStorage.getItem('user')!);
  private url: string;
  constructor(private http: HttpClient, private utilsService: UtilsService) {
    this.url = APP_CONFIG.csApi;
  }

  productGroups: ProductGroups[] = [
    { name: 'Sach' },
    { name: 'Kranken' },
    { name: 'Leben' },
  ];

  getProductGroups(): ProductGroups[] {
    this.hasPacketTwo();
    this.hasPacketThree();
    return this.productGroups;
  }

  getProductType(
    productTypeParams: ProductTypeParams
  ): Observable<Array<ProductType>> {
    const headers: HttpHeaders = new HttpHeaders({
      csrf: this.user.csrfToken,
    });
    return this.http.post<Array<ProductType>>(
      `${this.url}/ajax/GetProductType/${productTypeParams.withCriterion}`,
      null,
      {
        headers,
        responseType: 'json',
        withCredentials: false,
      }
    );
  }

  hasPacketTwo(): void {
    const products: Array<number> = [18, 19, 20, 21, 22, 24, 25];
    const packetTwo: boolean = products.some((products) => {
      return this.user.productTypeIds?.includes(products);
    });
    if (packetTwo) {
      this.productGroups.splice(1, 1, { name: 'Kranken' });
    }
  }

  hasPacketThree(): void {
    const products: Array<number> = [17, 23];
    const packetThree: boolean = products.some((products) => {
      return this.user.productTypeIds?.includes(products);
    });

    if (packetThree) {
      this.productGroups.splice(2, 1, { name: 'Leben' });
    }
  }

  getProductTypeSorted(withCriterion: boolean): Observable<Array<ProductType>> {
    const productTypeParams: ProductTypeParams = {
      withCriterion: withCriterion,
    };
    let subject = new Subject<Array<ProductType>>();
    this.getProductType(productTypeParams).subscribe(
      (response: Array<ProductType>) => {
        response = this.utilsService.sortArrayOfObjects(
          response,
          'name',
          'desc'
        );
        subject.next(response);
      }
    );

    return subject.asObservable();
  }

  getCurrentProductType(productId: number): Observable<ProductType> {
    const productTypeParams: ProductTypeParams = {
      withCriterion: false,
    };
    const subject = new Subject<ProductType>();
    this.getProductType(productTypeParams).subscribe(
      (response: Array<ProductType>) => {
        const currentProductType: ProductType = response.find(
          (r: ProductType) => r.id === productId
        )!;
        subject.next(currentProductType);
      }
    );

    return subject.asObservable();
  }

  private productNames: { [key: number]: string } = {
    1: 'Private Haftpflichtversicherung',
    2: 'Hausratversicherung',
    3: 'Wohngebäudeversicherung',
    4: 'Unfallversicherung',
    5: 'Kfz-Versicherung',
    6: 'Rechtsschutzversicherung',
    7: 'Tierhalterhaftpflichtversicherung (Hund)',
    8: 'Tierhalterhaftpflichtversicherung (Pferd)',
    17: 'Berufsunfähigkeitsversicherung',
    18: 'Private Krankenversicherung',
    19: 'Krankentagegeldversicherung (Angestellte)',
    20: 'Pflegezusatzversicherung',
    21: 'Stationäre Zusatzversicherung',
    22: 'Zahnzusatzversicherung',
    23: 'Risikolebensversicherung',
    24: 'Krankentagegeldversicherung (Selbstständige)',
    25: 'Restkostenkrankenversicherung (Beihilfe)',
  };

  getProductName(productId: number): string {
    return this.productNames[productId] || 'Versicherung';
  }

  getProductShortName(productId: number): string {
    const shortNames: { [key: number]: string } = {
      1: 'Private Haftpflicht',
      2: 'Hausrat',
      3: 'Wohngebäude',
      4: 'Unfall',
      5: 'Kfz',
      6: 'Rechtsschutz',
      7: 'THV (Hund)',
      8: 'THV (Pferd)',
      17: 'BU',
      18: 'PKV',
      19: 'Krankentagegeld (Angestellte)',
      20: 'Pflegezusatz',
      21: 'Stationäre',
      22: 'Zahn',
      23: 'Risikoleben',
      24: 'Krankentagegeld (Selbstständige)',
      25: 'Restkosten (Beihilfe)',
    };

    return shortNames[productId] || 'Versicherung';
  }

  findByCategory(category: ProductType[], index: number): ProductType[] {
    return category.filter(
      (product: ProductType) => product.product_type_sparte_id === index + 1
    );
  }
}
